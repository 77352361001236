<script setup lang="ts">
/**
 * !!! REWRITE -> USE NEW SWIPER ELEMENTS !!!
 * * FIXME: when used in tabs, the slider is jumpy
 */

import Icon from './Icon.vue'

const props = withDefaults(
  defineProps<{
    sliderId: string
    hasFourSlides?: boolean
  }>(),
  {
    hasFourSlides: false,
  }
)

const isInitialized = ref(false)
const onInit = () => {
  isInitialized.value = true
}

const breakpoints = {
  992: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
    cssMode: false,
  },
  1200: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 25,
    cssMode: false,
  },
  1400: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 36,
    cssMode: false,
  },
}

if (props.hasFourSlides) {
  breakpoints[1400] = {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 40,
    cssMode: false,
  }
}
</script>

<template>
  <div class="c-items-slider">
    <Swiper
      :class="['c-items-slider__swiper']"
      :wrapper-class="props.hasFourSlides ? 'has-4-slides' : ''"
      :css-mode="true"
      :slides-per-view="'auto'"
      :space-between="0"
      :modules="[SwiperNavigation]"
      :navigation="{
        enabled: true,
        prevEl: `.js-navigation-${sliderId} .sc-swiper-button--prev`,
        nextEl: `.js-navigation-${sliderId} .sc-swiper-button--next`,
      }"
      :breakpoints="breakpoints"
      @init="onInit"
    >
      <slot />
    </Swiper>

    <div
      :class="[
        'c-items-slider__arrows',
        `js-navigation-${sliderId}`,
        isInitialized && 'swiper-initialized',
      ]"
    >
      <button class="sc-swiper-button sc-swiper-button--prev">
        <Icon name="arrow-left-short" />
      </button>
      <button class="sc-swiper-button sc-swiper-button--next">
        <Icon name="arrow-right-short" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;

.c-items-slider {
  position: relative;

  &__swiper {
    padding: var(--container-padding);
    margin: var(--container-padding-negative);

    & > :deep(.swiper-wrapper > .swiper-slide) {
      align-self: stretch;
      height: auto;
    }

    @include breakpoint-down('md') {
      padding-left: 0;
      padding-right: 0;

      & > :deep(.swiper-wrapper > .swiper-slide) {
        width: 260px;
        align-self: stretch;
        height: auto;
        padding-left: 20px;

        &:last-child {
          width: 280px;
          padding-right: 20px;
        }
      }
    }

    &:not(.swiper-initialized) {
      & > :deep(.swiper-wrapper) {
        @include breakpoint-up('lg') {
          --swiper-items-per-view: 2;
          --swiper-space-between: 20px;

          display: grid;
          grid-template-columns: repeat(var(--swiper-items-per-view), 1fr);
          gap: 0 var(--swiper-space-between);
          grid-template-rows: 1fr;
          grid-auto-rows: 0px;
          overflow: hidden;

          .swiper-slide {
            min-width: 0;
          }
        }

        @include breakpoint-up('xl') {
          --swiper-items-per-view: 3;
          --swiper-space-between: 36px;
        }

        @include breakpoint-up('2xl') {
          &.has-4-slides {
            --swiper-items-per-view: 4;
            --swiper-space-between: 40px;
          }
        }
      }
    }
  }

  &__arrows {
    transition: opacity var(--transition-duration)
      var(--transition-timing-function);

    &:not(.swiper-initialized) {
      opacity: 0;
      pointer-events: none;
    }

    .sc-swiper-button {
      --swiper-button--size: 48px;
      --swiper-button--offset: -88px;
      --swiper-button--color: var(--color-dark);
      --swiper-button--border-color: var(--color-dark);
      --swiper-button--background-color: transparent;

      display: grid;
      place-items: center;
      position: absolute;
      z-index: 10;
      top: calc(50% - var(--swiper-button--size) / 2);
      width: var(--swiper-button--size);
      height: var(--swiper-button--size);
      padding: 0;
      margin: 0;
      border-radius: 50%;
      color: var(--swiper-button--color);
      border: 1px solid var(--swiper-button--border-color);
      background-color: var(--swiper-button--background-color);
      transition: all var(--transition-duration)
        var(--transition-timing-function);

      @media (max-width: 1550px) and (min-width: 1400px),
        (max-width: 1320px) and (min-width: 1200px) {
        --swiper-button--offset: -30px;
      }

      @include breakpoint-down('lg') {
        display: none;
      }

      &.swiper-button-disabled {
        --swiper-button--color: var(--color-solid-grey-dark);
        --swiper-button--border-color: var(--color-solid-grey-dark);
        opacity: 0.5;
      }

      &:not(.swiper-button-disabled):hover {
        --swiper-button--color: var(--color-light);
        --swiper-button--border-color: var(--color-dark);
        --swiper-button--background-color: var(--color-dark);
      }

      &--prev {
        left: var(--swiper-button--offset);
      }
      &--next {
        right: var(--swiper-button--offset);
      }
    }
  }
}
</style>
